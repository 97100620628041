import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useEmailAccountsAnalyticsMutation, useEmailAccountsMutation, useExportEmailAccountsMutation } from 'services/campaign-service';
import { visuallyHidden } from '@mui/utils';
import lodash from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Table,
  TableSortLabel,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Typography,
  Checkbox,
  Grid,
  //   Modal,
  //   TextField,
  Popover,
  GlobalStyles
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Search from 'layout/MainLayout/Header/HeaderContent/Search';
import { useSelector } from 'react-redux';
import SettingsModal from 'pages/components/emailWarmup/SettingsModal';
import { DropDown } from 'assets/general/DropDown';
import { EDSCalendarIcon } from 'assets/emailAccounts/emailDrawer/EDSettingsTab/EDSCalendarIcon';
import { useDispatch } from 'react-redux';
import { setDateRange } from 'store/reducers/users';
import { downloadCsv } from 'utils/util';
import { EmailFilterHasNoCustomTrackingDomainIcon } from 'assets/filterMenu/EmailFilterNoCustomTrackingDomain';
import { EmailFilterWarmupActiveIcon } from 'assets/filterMenu/EmailFilterWarmupActive';
import { EmailFilterWarmupPaused } from 'assets/filterMenu/EmailFilterWarmupPaused';
import { EmailFilterWarmupErrorsIcon } from 'assets/filterMenu/EmailFilterWarmupErrors';
import { EmailFilterPauseIcon } from 'assets/filterMenu/EmailFilterPausedIcon';
import { EmailFilterHasErrorIcon } from 'assets/filterMenu/EmailFilterHasError';
import ActionMenu from 'components/Menu';
import { useNavigate, useParams } from 'react-router-dom';
import LoaderCircle from 'components/LoaderCircle';
import CustomCheckbox from 'pages/components/campaign/CustomCheckbox';

import toast from "react-hot-toast";

// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useEnableWarmupMutation, usePauseWarmupMutation } from "services/user-service";

const filterButtons = [
  {
    name: 'Clear Filter',
    value: '',
    icon: (active) => <EmailFilterWarmupErrorsIcon color={active ? '#0071F6' : '#28287B'} />
  },
  {
    name: 'On Pause',
    value: 'paused',
    icon: (active) => <EmailFilterPauseIcon color={active ? '#0071F6' : '#28287B'} />
  },
  {
    name: 'Issues Detected',
    value: 'has_errors',
    icon: (active) => <EmailFilterHasErrorIcon color={active ? '#0071F6' : '#28287B'} />
  },
  {
    name: 'No Custom Tracking Domain',
    value: 'no_custom_tracking_domain',
    icon: (active) => <EmailFilterHasNoCustomTrackingDomainIcon color={active ? '#0071F6' : '#28287B'} />
  },
  {
    name: 'Warmup In Progress',
    value: 'warmup_active',
    icon: (active) => <EmailFilterWarmupActiveIcon color={active ? '#0071F6' : '#28287B'} />
  },
  {
    name: 'Warmup Paused',
    value: 'warmup_paused',
    icon: (active) => <EmailFilterWarmupPaused color={active ? '#0071F6' : '#28287B'} />
  },
  {
    name: 'Warmup Issues',
    value: 'warmup_has_errors',
    icon: (active) => <EmailFilterWarmupErrorsIcon color={active ? '#0071F6' : '#28287B'} />
  }
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getFormaDate(dateTimeString) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const date = new Date(dateTimeString);
  const formattedDate = `${monthNames[String(date.getMonth())]} ${String(date.getDate()).padStart(2, '0')}, ${date.getFullYear()}`;
  return formattedDate;
}

const headCells = [
  {
    id: 'email',
    align: 'left',
    disablePadding: false,
    label: 'E-MAIL'
  },
  {
    id: 'provider',
    align: 'left',
    disablePadding: true,
    label: 'EMAIL PROVIDER'
  },
  {
    id: 'status',
    align: 'left',
    disablePadding: true,
    label: 'STATUS'
  },
  {
    id: 'warmupStatus',
    align: 'left',
    disablePadding: true,
    label: 'WARMUP STATUS'
  },
  {
    id: 'createdAt',
    align: 'left',
    disablePadding: false,
    label: 'CREATED AT'
  },
  {
    id: 'user',
    align: 'center',
    disablePadding: false,
    label: 'USER'
  },
  {
    id: 'action',
    align: 'center',
    disablePadding: false,
    label: 'Action'
  }
];
function createData(email, emailProvider, status, warmupStatus, createdAt, id, userEmail, userId) {
  return {
    email,
    emailProvider,
    status,
    warmupStatus,
    createdAt,
    id,
    userEmail,
    userId,
  };
}

const ManageEmailAccount = () => {
  const [getEmailAccountInfo] = useEmailAccountsMutation();
  const [orderBy, setOrderBy] = useState('email');
  const [order, setOrder] = useState('asc');
  const [pagePerRows, setPagePerRows] = useState(15);
  const [totalUser, setTotalUser] = useState(0);
  const [page, setPage] = useState(0);
  const { searchData } = useSelector((state) => state.search);
  const [rows, setRows] = useState([]);
  const [userData, setUserData] = useState({});
  const [open, setOpen] = useState(false);
  const [userAccount, setUserAccount] = useState([]);
  const { dateRange } = useSelector((state) => state.users);
  const [refetch, setRefetch] = useState(false);
  const [data, setData] = useState([]);
  const [fetchEmailAccounts] = useEmailAccountsAnalyticsMutation();
  const [limit, setLimit] = useState(15);
  const navigate = useNavigate();
  const { id } = useParams();
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openFilter = Boolean(anchorEl);
  const popoverId = openFilter ? 'simple-popover' : undefined;

  const fetchEmailAccountInfo = async () => {
    setIsLoading(true);
    const searchDataObject = searchData?.length ? { search: searchData } : {};
    const dateRangeObject = {
      ...(userId && { id: userId }),
      ...(dateRange?.start && { start: dateRange.start }),
      ...(dateRange?.end && { end: dateRange.end })
    };

    const { data } = await getEmailAccountInfo({
      page: page + 1,
      offset: limit * page,
      limit,
      ...(filter && { filter: filter.value }),
      ...searchDataObject,
      ...dateRangeObject
    });

    if (data?.docs?.length) {
      const rows = data?.docs?.map((cur) => {
        return createData(
          cur.email,
          cur.provider,
          cur.status,
          cur.warmup.status,
          cur.createdAt,
          cur._id,
          cur.createdBy.email,
          cur?.createdBy?._id
        );
      });
      setRows(rows);
      setUserAccount(data?.docs);
      setTotalUser(Number(data?.total) || 0);
      setIsLoading(false);
    } else {
      setRows([]);
      setTotalUser(0);
      setIsLoading(false);
    }
  };

  const fetchEmailAccountsTab = async () => {
    setIsLoading(true);
    const searchDataObject = searchData?.length ? { search: searchData } : {};
    const { data } = await fetchEmailAccounts({
      ...(userId && { id: userId }),
      ...(dateRange?.start && { start: dateRange.start }),
      ...(dateRange?.end && { end: dateRange.end }),
      ...searchDataObject,
      ...(filter && { filter: filter.value }),
    });
    setData(data);
    setIsLoading(false);
  };

  function OrderTableHead({ order, orderBy, onRequestSort }) {
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => {
            if (userId && headCell.id === 'action') {
              return null;
            }
            return (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{
                  backgroundColor: 'rgb(242, 244, 246)',
                  color: 'rgb(40, 40, 123)',
                  fontSize: '13px',
                  fontWeight: '500'
                }}
              >
                {headCell.id !== 'action' &&
                headCell.id !== 'warmupTag' &&
                headCell.id !== 'plan' &&
                headCell.id !== 'managePlan' &&
                headCell.id !== 'appSumo' ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id && (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    )}
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
  OrderTableHead.propTypes = {
    order: PropTypes.string,
    orderBy: PropTypes.string
  };
  const handleSettingOpen = async (id) => {
    const row = userAccount?.find((i) => i._id === id);
    handleEmailAccount(row);
  };

  const handleClickOpen = (id) => {
    setUserId(id);
    setIsLoading(true);
    let encoded = window.btoa(id);
    navigate(`/manage/email-accounts/${encoded}`);
  };

  const actionMenuData = [
    {
      name: 'Manage',
      onChange: handleSettingOpen
    },
    {
      name: 'View User Accounts',
      onChange: handleClickOpen
    }
  ];
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (event) => {
    setPagePerRows(event.target.value);
    setLimit(Number(event.target.value));
    setPage(1);
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleEmailAccount = async (row) => {
    const userAccountDetails = userAccount?.find((i) => i._id === row?.id);
    setOpen(true);
    setUserData(userAccountDetails);
  };

  const handleCloseEmailAccount = async () => {
    if (refetch) fetchEmailAccountInfo();
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClearFilter = () => {
    setFilter(null);
  };

  useEffect(() => {
    fetchEmailAccountsTab();
    fetchEmailAccountInfo();
  }, [searchData, dateRange, page, limit, userId, filter]);

  useEffect(() => {
    setIsLoading(true);
    setPage(0);
  }, [userId, dateRange, searchData, filter]);

  useEffect(() => {
    if (id) {
      setUserId(window.atob(id));
    } else {
      setUserId(null);
    }
  }, [id]);


  const [pauseWarmup] = usePauseWarmupMutation();
  const [enableWarmup] = useEnableWarmupMutation();

    const handleUpdateWarmupStatus = async (warmupStatus, id) => {
      if (warmupStatus === "paused" || warmupStatus === "disabled") {
        const toastId = toast.loading("Loading...", { duration: Infinity });
        const { message, account } = await enableWarmup(id).unwrap();
        const updatedRows = rows.map(row => ({
          ...row,
          warmupStatus: account?._id === row?.id ? account.warmup.status : row.warmupStatus
        }));
        setRows(updatedRows);
        toast.success(message, { id: toastId, duration: 2000 });
      } else if (warmupStatus === "enabled") {
        const toastId = toast.loading("Loading...", { duration: Infinity });
        const { message, account } = await pauseWarmup(id).unwrap();
        const updatedRows = rows.map(row => ({
          ...row,
          warmupStatus: account?._id === row?.id ? account.warmup.status : row.warmupStatus
        }));
        setRows(updatedRows);
        toast.success(message, { id: toastId, duration: 2000 });
      }
    };
  

  return (
    <>
      <>
        <Grid container gap={2}>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: '#28287B',
                fontSize: '30px',
                fontWeight: 600,
                lineHeight: '28px',
                letterSpacing: '0px'
              }}
            >
              Email Accounts
            </Typography>
          </Grid>
          <Box
            sx={{
              width: 1,
              pl: 1,
              border: '1px solid #f0f0f0',
              borderRadius: '12px',
              p: 1.3
            }}
          >
            <Box
              sx={{
                width: 1,
                display: 'flex',
                justifyContent: 'spaceBetween'
              }}
            >
              <Grid container columnSpacing={3} rowSpacing={2}>
                <Grid item xs={6} sm={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      gap: 2,
                      borderRadius: '12px',
                      border: '1px solid rgba(33, 111, 237, 0.5)',
                      padding: 2,
                      cursor: 'pointer'
                    }}
                  >
                    <Box>
                      <EmailFilterHasNoCustomTrackingDomainIcon />
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#28287B',
                          fontWeight: '600',
                          fontSize: '15px'
                        }}
                      >
                        Total
                      </Typography>
                      <Typography>{data?.totalCount}</Typography>
                    </Box>
                  </Box>
                </Grid>{' '}
                <Grid item xs={6} sm={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      gap: 2,
                      borderRadius: '12px',
                      border: '1px solid rgba(33, 111, 237, 0.5)',
                      padding: 2,
                      cursor: 'pointer'
                    }}
                  >
                    <Box>
                      <EmailFilterWarmupActiveIcon />
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#28287B',
                          fontWeight: '600',
                          fontSize: '15px'
                        }}
                      >
                        Warmup Enable
                      </Typography>
                      <Typography>{data?.countWarmupActive}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      gap: 2,
                      borderRadius: '12px',
                      border: '1px solid rgba(33, 111, 237, 0.5)',
                      padding: 2,
                      cursor: 'pointer'
                    }}
                  >
                    <Box>
                      <EmailFilterWarmupPaused />
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#28287B',
                          fontWeight: '600',
                          fontSize: '15px'
                        }}
                      >
                        Warmup Disable
                      </Typography>
                      <Typography>{data?.countWarmupPaused}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      gap: 2,
                      borderRadius: '12px',
                      border: '1px solid rgba(33, 111, 237, 0.5)',
                      padding: 2,
                      cursor: 'pointer'
                    }}
                  >
                    <Box>
                      <EmailFilterWarmupErrorsIcon />
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#28287B',
                          fontWeight: '600',
                          fontSize: '15px'
                        }}
                      >
                        Warmup Errors
                      </Typography>
                      <Typography>{data?.countWarmupHasErrors}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      gap: 2,
                      borderRadius: '12px',
                      border: '1px solid rgba(33, 111, 237, 0.5)',
                      padding: 2,
                      cursor: 'pointer'
                    }}
                  >
                    <Box>
                      <EmailFilterPauseIcon />
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#28287B',
                          fontWeight: '600',
                          fontSize: '15px'
                        }}
                      >
                        Paused
                      </Typography>
                      <Typography>{data?.countPaused}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      gap: 2,
                      borderRadius: '12px',
                      border: '1px solid rgba(33, 111, 237, 0.5)',
                      padding: 2,
                      cursor: 'pointer'
                    }}
                  >
                    <Box>
                      <EmailFilterHasErrorIcon />
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#28287B',
                          fontWeight: '600',
                          fontSize: '15px'
                        }}
                      >
                        Errors
                      </Typography>
                      <Typography>{data?.countHasErrors}</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'start',
                alignItems: 'center',
                gap: '8px'
              }}
            >
              <FilterDayWeekMonth />
              <CustomFilter />
            </Box>
            <DownloadCsv rows={rows} />
          </Grid>
        </Grid>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'flex-start', sm: 'center' },
            mr: 3,
            my: 2,
            gap: 2
          }}
        >
          <Box sx={{ width: { xs: '97%', sm: '30%' }, ml: '-8px' }}>
            <Search />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'row-reverse', sm: 'row', gap: '8px' } }}>
            {filter?.name && (
              <Typography
                aria-describedby={id}
                onClick={handleClearFilter}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minWidth: '64px',
                  backgroundColor: 'rgba(33, 111, 237, 0.1)',
                  color: '#216fed',
                  border: '1px solid #80808052',
                  fontWeight: 600,
                  borderRadius: '6px',
                  '&:hover': {
                    backgroundColor: 'white'
                  },
                  padding: '6px 8px',
                  gap: '8px',
                  cursor: 'pointer'
                }}
              >
                {filter?.name}
                <CloseIcon />
              </Typography>
            )}
            <Button
              aria-describedby={popoverId}
              onClick={handleClick}
              sx={{
                width: 'auto',
                backgroundColor: 'white',
                color: '#28287B',
                border: '1px solid #80808052',
                fontWeight: 600,
                '&:hover': {
                  backgroundColor: 'white'
                },
                padding: '4px 8px'
              }}
            >
              Filter
            </Button>
            <Popover
              id={popoverId}
              open={openFilter}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              sx={{ mt: 0.5 }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // width: "fit-content",
                  p: 1,
                  width: '260px'
                }}
              >
                {filterButtons.map((item, i) => {
                  return (
                    <Button
                      key={i}
                      fullWidth
                      sx={{
                        py: 1,
                        px: 1,

                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        color: '#101828',
                        fontSize: '13px',
                        backgroundColor: filter?.name === item.name && '#F2F4F6'
                      }}
                      onClick={() => {
                        setFilter(item);
                        setPage(1);
                        handleClose();
                      }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        {item.icon(filter?.name === item.name)}
                        <Typography
                          sx={{
                            color: filter?.name === item.name ? '#0071F6' : '#28287B',
                            fontSize: '13px',
                            fontWeight: 700,
                            linHeight: '16px',
                            letterSpacing: '0px',
                            ml: 2
                          }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                    </Button>
                  );
                })}
              </Box>
            </Popover>
          </Box>
        </Box>
        {isLoading ? (
          <LoaderCircle />
        ) : (
          <Box
            sx={{
              border: '1px solid #ebebeb',
              borderRadius: '12px'
            }}
          >
            <TableContainer
              sx={{
                width: '100%',
                // height: 'calc(100vh - 200px)',
                overflowx: 'auto',
                maxHeight: '100vh', // 80vh
                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                boxShadow: 'rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 2px',
                borderRadius: '6px',
                color: 'rgb(16, 24, 40)',
                '& td, & th': { whiteSpace: 'nowrap' }
              }}
            >
              <GlobalStyles
                styles={{
                  '*::-webkit-scrollbar': {
                    width: '8px',
                    height: '8px'
                  },
                  '*::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgb(228, 228, 229)',
                    borderRadius: '10px',
                    border: '1px solid rgba(0, 0, 0, 0)'
                  },
                  '*::-webkit-scrollbar-track': {
                    borderRadius: '60px',
                    width: '4px',
                    backgroundColor: 'rgb(242, 244, 246)'
                  }
                }}
              />
              <Table
                aria-labelledby="tableTitle"
                sx={{
                  '& .MuiTableCell-root:first-of-type': {
                    pl: 2
                  },
                  '& .MuiTableCell-root:last-of-type': {
                    pr: 3
                  }
                }}
                stickyHeader
              >
                <OrderTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0
                          },
                          cursor: 'pointer'
                        }}
                        tabIndex={-1}
                        key={row.email}
                      >
                        <TableCell component="th" id={labelId} scope="row" align="left">
                          {row.email}
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" align="left">
                          {row.emailProvider}
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" align="left">
                          {row.status}
                        </TableCell>
                        <TableCell align="left">
                        <CustomCheckbox
                              name="warmup.basicSetting.alertBlock"
                              checked={row?.warmupStatus === "enabled"}
                              onChange={() => handleUpdateWarmupStatus(row?.warmupStatus, row?.id)}
                              // disabled={account.warmup.warmupDisable === true || warmupStatus === "disabled"}
                            />
                        </TableCell>
                        <TableCell align="left">{getFormaDate(row.createdAt)}</TableCell>
                        <TableCell align="center"> {row.userEmail}</TableCell>
                        {!userId && (
                          <TableCell align="center">
                            <ActionMenu options={actionMenuData} actionId={row.id} data={row} />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              sx={{
                border: '1px solid #f0f0f0',
                borderRadius: 2
              }}
              component="div"
              rowsPerPageOptions={[15, 25, 50, 100]}
              rowsPerPage={pagePerRows}
              onRowsPerPageChange={handleRowsPerPageChange}
              count={totalUser}
              page={page}
              onPageChange={handleChangePage}
            />
          </Box>
        )}

        <SettingsModal onClose={() => handleCloseEmailAccount()} setRefetch={setRefetch} open={open} userData={userData} />
      </>
    </>
  );
};

export default ManageEmailAccount;

function FilterDayWeekMonth() {
  const timelineButtons = [
    {
      name: 'Select Date Range',
      value: {
        start: null,
        end: null
      }
    },
    {
      name: 'Last 7 days',
      value: {
        start: new Date().setDate(new Date().getDate() - 7),
        end: Date.now()
      }
    },
    {
      name: 'Month to date',
      value: { start: new Date().setDate(1), end: Date.now() }
    },
    {
      name: 'Last 4 weeks',
      value: {
        start: new Date().setDate(new Date().getDate() - 28),
        end: Date.now()
      }
    },
    {
      name: 'Last 3 months',
      value: {
        start: new Date().setMonth(new Date().getMonth() - 3),
        end: Date.now()
      }
    },
    {
      name: 'Last 6 months',
      value: {
        start: new Date().setMonth(new Date().getMonth() - 6),
        end: Date.now()
      }
    },
    {
      name: 'Last 12 months',
      value: {
        start: new Date().setMonth(new Date().getMonth() - 12),
        end: Date.now()
      }
    }
  ];
  const [timeline, setTimeline] = React.useState(timelineButtons[0]);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'left',
          fontSize: '13px',
          fontWeight: 700,
          lineHeight: '16.38px',
          color: '#28287B',
          backgroundColor: '#fff',
          px: 1.5,
          '&:hover': {
            backgroundColor: '#fff'
          },
          border: '1px solid #E4E4E5',
          height: '36px'
        }}
        onClick={handleClick}
      >
        {timeline?.name}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ml: 1
          }}
        >
          <DropDown />
        </Box>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ mt: 0.5 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 1.1,
            width: '200px'
          }}
        >
          {timelineButtons.map((item, i) => {
            return (
              <Button
                key={i}
                fullWidth
                sx={{
                  py: 1.1,
                  px: 2,
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  color: '#101828',
                  fontSize: '13px',
                  backgroundColor: timeline?.name === item.name && 'rgb(33, 111, 237, 0.1)'
                }}
                onClick={() => {
                  setTimeline(item);
                  dispatch(
                    setDateRange({
                      name: item?.name,
                      ...item?.value
                    })
                  );
                  setAnchorEl(null);
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}
                >
                  {item.name}
                </Box>
              </Button>
            );
          })}
        </Box>
      </Popover>
    </>
  );
}

/*
Implement Custom filter
*/
function CustomFilter() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open2 = Boolean(anchorEl);
  const id2 = open2 ? 'simple-popover' : undefined;

  const handleApplyClick = () => {
    //Date, month, year of start date
    const startMonth = startDate.toDate().getMonth() + 1;
    const startDateNum = startDate.toDate().getDate();
    const startYear = startDate.toDate().getFullYear();

    //Date, month, year of end date
    const endMonth = endDate.toDate().getMonth() + 1;
    const endDateNum = endDate.toDate().getDate();
    const endYear = endDate.toDate().getFullYear();

    // formatted date strings
    const startDateString = `${startYear}-${startMonth < 10 ? '0' + startMonth : startMonth}-${
      startDateNum < 10 ? '0' + startDateNum : startDateNum
    }`;
    const endDateString = `${endYear}-${endMonth < 10 ? '0' + endMonth : endMonth}-${endDateNum < 10 ? '0' + endDateNum : endDateNum}`;

    // date to milliseconds
    const start = new Date(startDateString).getTime();
    const end = new Date(endDateString).getTime();
    dispatch(setDateRange({ name: 'Custom Range', start, end }));
    setAnchorEl(null);
  };

  return (
    <>
      {' '}
      <Button
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'left',
          fontSize: '13px',
          fontWeight: 700,
          lineHeight: '16.38px',
          color: '#28287B',
          backgroundColor: '#fff',
          px: 1.5,
          pr: '36px',
          '&:hover': {
            backgroundColor: '#fff'
          },
          border: '1px solid #E4E4E5',
          height: '36px'
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mr: 1
          }}
        >
          <EDSCalendarIcon />
        </Box>
        Custom Range
      </Button>
      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ mt: 0.5 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            p: 2,
            width: 'fit-content'
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
              letterSpacing: '0em',
              color: '#28287B',
              mr: 2,
              mb: 1
            }}
          >
            From:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker disableFuture maxDate={endDate} value={startDate} onChange={(date) => setStartDate(date)} />
          </LocalizationProvider>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
              letterSpacing: '0em',
              color: '#28287B',
              mr: 2,
              mt: 2,
              mb: 1
            }}
          >
            To:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker disableFuture minDate={startDate} value={endDate} onChange={(date) => setEndDate(date)} />
          </LocalizationProvider>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              mt: 2
            }}
          >
            <Button
              onClick={() => {
                setStartDate(null);
                setEndDate(null);
                dispatch(
                  setDateRange({
                    name: 'Month to date',
                    start: '',
                    end: ''
                  })
                );
                setAnchorEl(null);
              }}
            >
              Clear
            </Button>
            <Button variant="contained" disabled={!(startDate && endDate)} onClick={handleApplyClick}>
              Apply
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

// Download CSV
function DownloadCsv({ rows }) {
  const [downloadType, setDownloadType] = React.useState(null);
  const [show, setShow] = React.useState(null);
  const [exportAllEmailAccount] = useExportEmailAccountsMutation();

  const { dateRange } = useSelector((state) => state.users);
  const { searchData } = useSelector((state) => state.search);

  const handleClick = (event) => {
    setShow(event.currentTarget);
  };

  const handleClose = () => {
    setShow(null);
  };

  const fetchAllEmailAccounts = async () => {
    const response = await exportAllEmailAccount({
      ...(searchData?.length && { search: searchData }),
      ...(dateRange?.start && { start: dateRange?.start }),
      ...(dateRange?.end && { end: dateRange?.end })
    });
    return response?.data;
  };

  //csv download function
  const handleChange = async (type) => {
    setDownloadType(type);
    let datas = null;
    if (type === 'page') {
      datas = rows;
    } else if (type === 'all') {
      datas = await fetchAllEmailAccounts();
    }

    if (datas) {
      const list = datas?.map((data) => {
        let dataList = {};
        if (typeof data?.name === 'object') {
          dataList = {
            email: data?.email || '',
            id: data?._id || '',
            name: data?.name?.first + ' ' + data?.name?.last || '',
            emailProvider: data?.provider,
            status: data?.status,
            warmupStatus: data?.warmup?.status || '',
            createdAt: data?.createdAt || ''
          };

          if (dataList != undefined) {
            return lodash.pick(dataList, ['email', 'id', 'name', 'emailProvider', 'status', 'warmupStatus', 'createdAt']);
          }
        } else {
          return lodash.pick(data, ['email', 'id', 'name', 'emailProvider', 'status', 'warmupStatus', 'createdAt']);
        }
      });
      handleClose();
      downloadCsv('Email Accounts', list);
    }
    setDownloadType(null);
  };

  const open = Boolean(show);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Box>
        <Button variant="contained" onClick={handleClick}>
          Download CSV
        </Button>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={show}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Button
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '0px 12px 0px 0px'
          }}
          onClick={() => handleChange('page')}
        >
          <Checkbox inputProps={{ 'aria-label': 'controlled' }} size="small" checked={downloadType === 'page'} />
          <Typography>Select page</Typography>
        </Button>
        <Button
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '0px 12px 0px 0px'
            // gap: 1,
          }}
          onClick={() => handleChange('all')}
        >
          <Checkbox inputProps={{ 'aria-label': 'controlled' }} size="small" checked={downloadType === 'all'} />
          <Typography>Select All</Typography>
        </Button>
      </Popover>
    </>
  );
}
