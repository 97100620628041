/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useGetAllUsersQuery, useGetAllWorkspacesMutation } from 'services/user-service';
import { planNames } from '../../../config';
import Search from '../../../layout/MainLayout/Header/HeaderContent/Search';
import toast from 'react-hot-toast';
import {
  Box,
  Link,
  Table,
  TableSortLabel,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import GlobalStyles from '@mui/material/GlobalStyles';
import LoaderCircle from 'components/LoaderCircle';
import { useDispatch, useSelector } from 'react-redux';
import {
  useDisableUserMutation,
  useEnableUserMutation,
  useGetPlanUsageMutation,
  useLazyGetUserAuthTokenQuery,
} from 'services/auth-service';
import PlanSetting from 'pages/components/planSettings/PlanSetting';
import WarmupSettings from 'pages/components/warmupSettings/WarmupSettings';
import ActionMenu from 'components/Menu';
import ManageSetting from 'pages/components/ManagePlanSettings/ManageSetting';
import Popover from '@mui/material/Popover';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CustomCheckbox from 'pages/components/campaign/CustomCheckbox';
import { setUserType, setUsers } from 'store/reducers/users';
import { Total } from 'assets/campaignDetailsLeads/Total';
import { Paid } from 'assets/dashboard/users/Paid';
import { Free } from 'assets/dashboard/users/Free';
import { Enabled } from 'assets/dashboard/users/Enabled';
import { Appsumo } from 'assets/dashboard/users/Appsumo';
import ChangeAppSumoPlan from 'pages/components/ChangeAppSumoPlan/ChangeAppSumoPlan';
import DeleteConformation from 'pages/components/DeleteConformation/DeleteConformation';
import { useNavigate } from 'react-router-dom';
import { ChangePassword } from './ChangePassword';
import { ChangeEnterpriseUser } from './ChangeEnterpriseUser';
import { UserCard } from './UserCard';
import { LifeTime } from 'assets/dashboard/users/LifeTime';
import EmailSetting from 'pages/components/EmailSettings/EmailSettings';
import IconButton from '@mui/material/IconButton';
import ApiOutlinedIcon from '@mui/icons-material/ApiOutlined';
import UserStandardPlan from 'pages/components/UserStandardPlan/UserStandardPlan';

function createData(
  email,
  name,
  plan,
  Appsumo,
  appSumoRefund,
  managePlan,
  warmupTag,
  createdAt,
  lastLogout,
  status,
  action,
  id,
  appSumoCode,
  EarlyBirdPlan,
) {
  return {
    email,
    name,
    plan,
    Appsumo,
    appSumoRefund,
    managePlan,
    warmupTag,
    createdAt,
    lastLogout,
    status,
    action,
    id,
    appSumoCode,
    EarlyBirdPlan,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getFormaDate(dateTimeString) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const date = new Date(dateTimeString);
  const formattedDate = `${monthNames[String(date.getMonth())]} ${String(
    date.getDate()
  ).padStart(2, '0')}, ${date.getFullYear()}`;
  return formattedDate;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'email',
    align: 'left',
    disablePadding: false,
    label: 'E-MAIL',
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: true,
    label: 'NAME',
  },
  {
    id: 'leads',
    align: 'center',
    disablePadding: true,
    label: 'LEADS PLAN',
  },
  {
    id: 'sendingWarmup',
    align: 'center',
    disablePadding: true,
    label: 'SENDING WARMUP PLAN',
  },
  {
    id: 'appSumo',
    align: 'center',
    disablePadding: true,
    label: 'APPSUMO',
  },
  {
    id: 'EarlyBirdPlan',
    align: 'center',
    disablePadding: true,
    label: 'Life Time Deal',
  },
  {
    id: 'appSumoRefund',
    align: 'center',
    disablePadding: true,
    label: 'APPSUMO REFUND',
  },
  {
    id: 'managePlan',
    align: 'center',
    disablePadding: true,
    label: 'MANAGE SUBSCRIPTION',
  },
  {
    id: 'Enterprise',
    align: 'left',
    disablePadding: false,
    label: 'Enterprise',
  },
  {
    id: 'warmupTag',
    align: 'left',
    disablePadding: false,
    label: 'WARMUPTAG',
  },
  {
    id: 'createdAt',
    align: 'left',
    disablePadding: false,
    label: 'CREATED AT',
  },
  {
    id: 'lastLogout',
    align: 'left',
    disablePadding: false,
    label: 'LAST LOGOUT',
  },
  {
    id: 'status',
    align: 'left',
    disablePadding: false,
    label: 'STATUS',
  },
  {
    id: 'changepassword',
    align: 'cener',
    disablePadding: false,
    label: 'Change Password',
  },
  {
    id: 'action',
    align: 'center',
    disablePadding: false,
    label: 'ACTION',
  },
  {
    id: 'delete',
    align: 'center',
    disablePadding: false,
    label: 'DELETE',
  },
];

function OrderTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              backgroundColor: 'rgb(242, 244, 246)',
              color: 'rgb(40, 40, 123)',
              fontSize: '13px',
              fontWeight: '500',
            }}
          >
            {headCell.id !== 'action' &&
              headCell.id !== 'warmupTag' &&
              headCell.id !== 'plan' &&
              headCell.id !== 'managePlan' &&
              headCell.id !== 'appSumo' ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id && (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                )}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

OrderTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

export default function OrderTable() {
  const navigate = useNavigate();
  const [orderBy, setOrderBy] = useState('email');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openWarmup, setOpenWarmup] = useState(false);
  const [openChangeAppSumoPlan, setOpenChangeAppSumoPlan] = useState(false);
  const [subscriptionOpen, setSubscriptionOpen] = useState(false);
  const [totalUser, setTotaluser] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();
  const { dateRange } = useSelector((state) => state.users);
  const { searchData } = useSelector((state) => state.search);
  const [usageData, setUsageData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [getPlanUsage] = useGetPlanUsageMutation();
  const [getToken, { data: tokenData }] = useLazyGetUserAuthTokenQuery();
  const [userData, setUserData] = useState({});
  const [refetchUser, setRefetchUser] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState('Active');
  const [statusActive] = useEnableUserMutation();
  const [statusDisable] = useDisableUserMutation();
  const [statusLoading, setStatusLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [pagePerRows, setPagePerRows] = useState(15);
  const [deleteConformation, setDeleteConformation] = useState(false);
  const [getAllWorkspaces] = useGetAllWorkspacesMutation();
  const [allWorkspaces, setAllWorkspaces] = useState([]);
  const [changeUserEmail, setChangeUserEmail] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [openEnterpriseModal, setOpenEnterpriseModal] = useState(false);
  const [openPlanModal,setOpenPlanModal] = useState(false);
 
  const {
    data,
    refetch,
    isLoading: refetchLoader,
  } = useGetAllUsersQuery({
    sortBy: orderBy,
    page: page + 1,
    limit: pagePerRows,
    order,
    userType: name,
    ...(dateRange?.start && { start: dateRange?.start }),
    ...(dateRange?.end && { end: dateRange?.end }),
    ...(searchData?.length && { search: searchData }),
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const opened = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClickOpen = async (id) => {
    setStatusLoading(true);
    const { data } = await getPlanUsage(id);
    const workspaces = await getAllWorkspaces({
      params: _.pickBy({ id }),
    }).unwrap();
    setAllWorkspaces(workspaces);
    setUserId(id);
    setUsageData(data);
    setOpen(true);
    setStatusLoading(false);
  };

  const handleClickOpenWarmup = async (id) => {
    setStatusLoading(true);
    const { data } = await getPlanUsage(id);
    setUserId(id);
    setUsageData(data);
    setOpenWarmup(true);
    setStatusLoading(false);
  };

  const handleClickUserEmail = (id, email) => {
    setUserId(id);
    setUserEmail(email);
    setChangeUserEmail(true);
  };

  const handleClickOpenChangeWarmupPlans = async (UserData) => {
    setStatusLoading(true);
    setUserId(UserData.id);
    setUsageData(UserData);
    setOpenChangeAppSumoPlan(true);
    setStatusLoading(false);
  };

  const handleClickManageEmailAccount = async (UserData) => {
    let encoded = window.btoa(UserData);
    navigate(`/manage/email-accounts/${encoded}`);
  };

  const handleStandardPlans = async (id) => {
    try {
      const workspaces = await getAllWorkspaces({
        params: _.pickBy({ id }),
      }).unwrap();
      setAllWorkspaces(workspaces);
      setUserId(id);
      setOpenPlanModal(true);
    } catch (error) {
      toast.error('Error fetching workspaces:', error);
    }
  };

  const handleCloseAppSumoPlan = () => {
    setUsageData(null);
    setUserId(null);
    setOpenChangeAppSumoPlan(false);
  };

  const handleDeleteAction = (deleteId) => {
    setDeleteConformation(true);
    setUserId(deleteId);
  };

  const handleCloseDeleteConformation = () => {
    setDeleteConformation(false);
  };

  const handleCloseWarmup = () => {
    setUsageData(null);
    setUserId(null);
    setOpenWarmup(false);
  };

  const handleClose = () => {
    setUsageData(null);
    setUserId(null);
    setOpen(false);
    setOpenPlanModal(false);
  };

  const handleSubScriptionManage = (option, data) => {
    setUserData(data);
    setSubscriptionOpen(true);
  };
  const handleCloseSubScriptionManage = () => {
    setUserData({});
    setSubscriptionOpen(false);
  };

  const handleRowsPerPageChange = (event) => {
    setPagePerRows(event.target.value);
  };

  const toggleHandleClick = async (toggle, id) => {
    try {
      if (toggle) {
        //true
        setStatusLoading(true);
        await statusDisable({
          userId: id,
        });
        await refetch();
        setStatusLoading(false);
        toast.success('User is blocked');
      } else {
        //false
        setStatusLoading(true);
        await statusActive({
          userId: id,
        });
        await refetch();
        setStatusLoading(false);
        toast.success('User is active');
      }
    } catch (error) {
      setStatusLoading(false);
    }
  };

  const actionMenuData = [
    {
      name: 'Edit Usage',
      onChange: handleClickOpen,
    },
    {
      name: 'Manage',
      onChange: handleSubScriptionManage,
    },
    {
      name: 'Warmup Emails',
      onChange: handleClickOpenWarmup,
    },
    {
      name: 'Change AppSumo Plan',
      onChange: handleClickOpenChangeWarmupPlans,
    },
    {
      name: 'Manage Email Account ',
      onChange: handleClickManageEmailAccount,
    },
    {
      name: 'Manage Standard Plan',
      onChange: handleStandardPlans,
    },
    {
      name: 'Change User Email',
      onChange: (id, data) => handleClickUserEmail(id, data.email),
    },
  ];

  const onActionMenuClick = async (id) => {
    setRedirect(true);
    await getToken({ id });
  };

  useEffect(() => {
    const handleRefetchData = async () => {
      setStatusLoading(true);
      await refetch();
      setStatusLoading(false);
    };

    if (refetchUser) {
      handleRefetchData();
      setRefetchUser(false);
    } else {
      handleRefetchData();
    }
  }, [
    dateRange,
    name,
    page,
    orderBy,
    order,
    searchData?.length > 0,
    refetchUser,
  ]);

  useEffect(() => {
    if (data?.users?.docs?.length) {
      const rows = data?.users?.docs?.map((cur) => {
        return createData(
          cur.email,
          cur.name.first + ' ' + cur.name.last,
          cur.plan,
          cur.assignedPlan,
          cur.isAppSumoRefund,
          cur.managePlan,
          cur.warmupTag,
          cur.createdAt,
          cur.lastLogout,
          cur.isDeleted,
          cur.action,
          cur._id,
          cur?.appSumoCode,
          cur?.EarlyBirdPlan,
        );
      });
      setRows(rows);
      dispatch(setUsers(rows));
      setTotaluser(Number(data?.users?.totalDocs) || 0);
    } else {
      setTotaluser(0);
    }
  }, [data]);

  useEffect(() => {
    if (tokenData?.authToken && redirect) {
      setRedirect(false);
      window.open(
        `${process.env.REACT_APP_FRONTEND_URL}/verify?token=${tokenData?.authToken}&skipUpdate=true`,
        '_blank'
      );
    }
  }, [tokenData]);

  if (refetchLoader) {
    return <LoaderCircle />;
  }
  const userStats = data?.users?.statistics || {};

  const handleOpenEnterprise = (id) => {
    setUserId(id);
    setOpenEnterpriseModal(true);
  }

  return (
    <>
      <>
        <Box
          sx={{
            width: 1,
            pl: 1,
            border: '1px solid #f0f0f0',
            borderRadius: '12px',
            p: 1.3,
          }}
        >
          <Box
            sx={{
              width: 1,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'space-between',
              flexWrap: 'wrap',
              gap: 1
            }}
          >
            <UserCard
              Icon={Total}
              title="All Users"
              count={userStats.activeUsers + userStats.deletedUsers}
            />
            <UserCard
              Icon={Paid}
              title="Paid Users"
              count={userStats.paidUsers}
            />

            <UserCard
              Icon={Free}
              title="Free Users"
              count={userStats.freeUsers}
            />

            <UserCard
              Icon={Enabled}
              title="Enabled Users"
              count={userStats.activeUsers}
            />

            <UserCard
              Icon={Appsumo}
              title="Appsumo Users"
              count={userStats.appSumoUsers}
            />
            <UserCard
              Icon={LifeTime}
              title="Life Time Users"
              count={userStats?.lifeTimeUser}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'flex-start', sm: 'center' },
            mr: 3,
            my: 2,
            gap: 2,
          }}
        >
          <Box sx={{ width: { xs: '97%', sm: '30%' } }}>
            <Search />
          </Box>
          <Box sx={{ px: 1 }}>
            <Box
              id="outlined-basic"
              label="Outlined"
              variant="outlined"
              onClick={handleClick}
              sx={{
                width: 200,
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                p: 1,
                border: '2px solid rgba(33, 111, 237, 0.5)',
                fontSize: '15px',
                borderRadius: '12px',
                color: '#28287B',
                fontWeight: '600',
              }}
            >
              <Box>{name}</Box>
              <Box sx={{ display: 'flex' }}>
                {opened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Box>
            </Box>
          </Box>

          <Popover
            sx={{ mt: 0.7 }}
            id={id}
            open={opened}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'bottom',
            }}
          >
            <Box sx={{ width: 200, p: 1, gap: 1 }}>
              <Typography
                onClick={() => {
                  handleClosePopover();
                  setName('Active');
                  dispatch(setUserType('Active'));
                }}
                sx={{
                  border: '1px solid #E4E4E5',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  p: 0.5,
                  mb: 0.3,
                  '&:hover': {
                    backgroundColor: '#739fe5',
                    color: 'white',
                  },
                }}
              >
                {'Active'}
              </Typography>
              <Typography
                onClick={() => {
                  handleClosePopover();
                  setName('Not Active');
                  dispatch(setUserType('Not Active'));
                }}
                sx={{
                  border: '1px solid #E4E4E5',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  p: 0.5,
                  mb: 0.3,
                  '&:hover': {
                    backgroundColor: '#739fe5',
                    color: 'white',
                  },
                }}
              >
                {'Not Active'}
              </Typography>
              <Typography
                onClick={() => {
                  handleClosePopover();
                  setName('All');
                  dispatch(setUserType('All'));
                }}
                sx={{
                  border: '1px solid #E4E4E5',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  p: 0.5,
                  mb: 0.3,
                  '&:hover': {
                    backgroundColor: '#739fe5',
                    color: 'white',
                  },
                }}
              >
                {'All'}
              </Typography>
              <Typography
                onClick={() => {
                  handleClosePopover();
                  setName('Paid');
                  dispatch(setUserType('Paid'));
                }}
                sx={{
                  border: '1px solid #E4E4E5',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  p: 0.5,
                  mb: 0.3,
                  '&:hover': {
                    backgroundColor: '#739fe5',
                    color: 'white',
                  },
                }}
              >
                {'Paid'}
              </Typography>
              <Typography
                onClick={() => {
                  handleClosePopover();
                  setName('Free');
                  dispatch(setUserType('Free'));
                }}
                sx={{
                  border: '1px solid #E4E4E5',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  p: 0.5,
                  mb: 0.3,
                  '&:hover': {
                    backgroundColor: '#739fe5',
                    color: 'white',
                  },
                }}
              >
                {'Free'}
              </Typography>
              <Typography
                onClick={() => {
                  handleClosePopover();
                  setName('Appsumo');
                  dispatch(setUserType('Appsumo'));
                }}
                sx={{
                  border: '1px solid #E4E4E5',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  p: 0.5,
                  mb: 0.3,
                  '&:hover': {
                    backgroundColor: '#739fe5',
                    color: 'white',
                  },
                }}
              >
                {'Appsumo'}
              </Typography>
              <Typography
                onClick={() => {
                  handleClosePopover();
                  setName('Appsumo Refunded');
                  dispatch(setUserType('Appsumo Refunded'));
                }}
                sx={{
                  border: '1px solid #E4E4E5',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  p: 0.5,
                  mb: 0.3,
                  '&:hover': {
                    backgroundColor: '#739fe5',
                    color: 'white',
                  },
                }}
              >
                {'Appsumo Refunded'}
              </Typography>
              <Typography
                onClick={() => {
                  handleClosePopover();
                  setName('LifeTimeUser');
                  dispatch(setUserType('LifeTimeUser'));
                }}
                sx={{
                  border: '1px solid #E4E4E5',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  p: 0.5,
                  mb: 0.3,
                  '&:hover': {
                    backgroundColor: '#739fe5',
                    color: 'white',
                  },
                }}
              >
                {'Life Time User'}
              </Typography>
            </Box>
          </Popover>
        </Box>
        {statusLoading ? (
          <LoaderCircle />
        ) : totalUser === 0 ? (
          <>
            <Box
              sx={{
                width: 1,
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '2rem',
              }}
            >
              No {name == 'All' ? '' : name == 'Not Active' ? 'InActive' : name}{' '}
              User Available
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                border: '1px solid #ebebeb',
                borderRadius: '12px',
              }}
            >
              <TableContainer
                sx={{
                  width: '100%',
                  // height: 'calc(100vh - 200px)',
                  overflowx: 'auto',
                  maxHeight: '100vh', // 80vh
                  transition:
                    'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  boxShadow:
                    'rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 2px',
                  borderRadius: '6px',
                  color: 'rgb(16, 24, 40)',
                  '& td, & th': { whiteSpace: 'nowrap' },
                }}
              >
                <GlobalStyles
                  styles={{
                    '*::-webkit-scrollbar': {
                      width: '8px',
                      height: '8px',
                    },
                    '*::-webkit-scrollbar-thumb': {
                      backgroundColor: 'rgb(228, 228, 229)',
                      borderRadius: '10px',
                      border: '1px solid rgba(0, 0, 0, 0)',
                    },
                    '*::-webkit-scrollbar-track': {
                      borderRadius: '60px',
                      width: '4px',
                      backgroundColor: 'rgb(242, 244, 246)',
                    },
                  }}
                />
                <Table
                  aria-labelledby="tableTitle"
                  sx={{
                    '& .MuiTableCell-root:first-of-type': {
                      pl: 2,
                    },
                    '& .MuiTableCell-root:last-of-type': {
                      pr: 3,
                    },
                  }}
                  stickyHeader
                >
                  <OrderTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map(
                      (row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            sx={{
                              '&:last-child td, &:last-child th': {
                                border: 0,
                              },
                            }}
                            tabIndex={-1}
                            key={row.email}
                          >
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              align="left"
                            >
                              <Link
                                color="secondary"
                                component={RouterLink}
                                to=""
                              >
                                {row.email}
                              </Link>
                            </TableCell>
                            <TableCell
                              sx={{
                                pl: '12px !important',
                                align: 'left',
                              }}
                            >
                              {row.name}
                            </TableCell>
                            <TableCell align="center">
                              <Typography sx={{ fontSize: '11px' }}>
                                {row.plan?.subscription?.leads?.active
                                  ? planNames[
                                  row.plan?.subscription?.leads?.planId
                                  ]
                                  : '-'}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography sx={{ fontSize: '11px' }}>
                                {row.plan?.subscription?.sendingWarmup?.active
                                  ? planNames[
                                  row.plan?.subscription?.sendingWarmup
                                    ?.planId
                                  ]
                                  : '-'}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {row.Appsumo ? row.Appsumo : '-'}
                            </TableCell>
                            <TableCell align="center">
                              {row.EarlyBirdPlan ? row.EarlyBirdPlan : '-'}
                            </TableCell>
                            <TableCell align="center">
                              {row.appSumoRefund === true
                                ? 'Yes'
                                : row.appSumoRefund === false
                                  ? 'No'
                                  : '-'}
                            </TableCell>
                            <TableCell align="center">
                              <ActionMenu
                                options={actionMenuData}
                                actionId={row.id}
                                data={row}
                              />
                            </TableCell>
                            <TableCell align="left">
                              <IconButton onClick={() => handleOpenEnterprise(row?.id)} sx={{ color: "#757575" }}>
                                <ApiOutlinedIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell align="left">{row.warmupTag}</TableCell>
                            <TableCell align="left">
                              {getFormaDate(row.createdAt)}
                            </TableCell>
                            <TableCell align="left">
                              {getFormaDate(row.lastLogout)}
                            </TableCell>
                            <TableCell align="left">
                              <CustomCheckbox
                                size={'small'}
                                checked={!row.status}
                                onClick={() => {
                                  toggleHandleClick(!row.status, row.id);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <ChangePassword id={row.id} email={row.email} />
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                type="button"
                                onClick={() => {
                                  onActionMenuClick(row.id);
                                }}
                                sx={{
                                  backgroundColor: '#216fed', //'#1890ff'
                                  color: '#ffffff',
                                  border: 'none',
                                  padding: '4px 12px',
                                  fontSize: '14px',
                                  cursor: 'pointer',
                                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                  transition: 'box-shadow 0.3s',
                                  '&:hover': {
                                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                                    backgroundColor: '#216fed',
                                  },
                                }}
                              >
                                Manage
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                type="button"
                                onClick={() => {
                                  handleDeleteAction(row.id);
                                }}
                                sx={{
                                  backgroundColor: '#ff0000', //'#1890ff'
                                  color: '#ffffff',
                                  border: 'none',
                                  padding: '4px 12px',
                                  fontSize: '14px',
                                  cursor: 'pointer',
                                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                  transition: 'box-shadow 0.3s',
                                  '&:hover': {
                                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                                    backgroundColor: '#ff0000',
                                  },
                                }}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                sx={{
                  border: '1px solid #f0f0f0',
                  borderRadius: 2,
                }}
                component="div"
                rowsPerPageOptions={[15, 25, 50, 100]}
                rowsPerPage={pagePerRows}
                onRowsPerPageChange={handleRowsPerPageChange}
                count={totalUser}
                page={page}
                onPageChange={handleChangePage}
              />
            </Box>
          </>
        )}
      </>
      <PlanSetting
        open={open}
        onClose={handleClose}
        // usageData={usageData}
        userId={userId}
        allWorkspaces={allWorkspaces}
      />
      <WarmupSettings
        open={openWarmup}
        onClose={handleCloseWarmup}
        usageData={usageData}
        userId={userId}
      />
      <ManageSetting
        open={subscriptionOpen}
        onClose={handleCloseSubScriptionManage}
        userData={userData}
        setRefetchUser={setRefetchUser}
      />
      <ChangeAppSumoPlan
        open={openChangeAppSumoPlan}
        onClose={handleCloseAppSumoPlan}
        usageData={usageData}
        userId={userId}
        setRefetchUser={setRefetchUser}
        userData={userData}
      />
      <DeleteConformation
        open={deleteConformation}
        onClose={handleCloseDeleteConformation}
        usageData={usageData}
        userId={userId}
        setRefetchUser={setRefetchUser}
      />
      <EmailSetting
        open={changeUserEmail}
        onClose={() => setChangeUserEmail(false)}
        userId={userId}
        userEmail={userEmail}
      />
      <ChangeEnterpriseUser 
        open={openEnterpriseModal}
        onClose={() => setOpenEnterpriseModal(false)}
        id={userId}
      />
      <UserStandardPlan
        open={openPlanModal}
        onClose={handleClose}
        allWorkspaces={allWorkspaces}
        userId={userId}
      />
    </>
  );
}
