import { useCallback, useEffect, useState } from "react";
import { TableContainer, Box, InputAdornment, Button, OutlinedInput, SvgIcon, Table, Typography, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, CircularProgress, Pagination, Checkbox } from "@mui/material";
// import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import { SearchOutlined } from '@ant-design/icons';

import { CustomDropdown } from "./statusList";
import { CloseOutlined, LanguageOutlined } from "@mui/icons-material";
// import { API_SERVICE_BACKEND3 } from "src/config";
import config from "../../config"
import axios from "axios";
import moment from "moment";
import { Stack } from "@mui/system";
import toast from "react-hot-toast";
const Socialicon = "/socialicon.png"
const API_SERVICE_BACKEND3 = config.API_BASE_URL;
const statusList = [
    {
        label: "All Status",
        // icon: checkIcon,
        color: "#12B981",
        value: ""
    },
    {
        label: "Completed",
        icon: "/checkIcon.png",
        color: "#12B981",
        value: "Completed"
    },
    {
        label: "Pending",
        icon: "/pendingIcon.png",
        color: "#FF5C1F",
        value: "Pending"
    },
    {
        label: "Canceled",
        icon: "/unCheckIcon.png",
        color: "#E20E0E",
        value: "Cancelled"
    },
];
const statusListWithoutAll = [
    {
        label: "Completed",
        icon: "/checkIcon.png",
        color: "#12B981",
        value: "Completed"
    },
    {
        label: "Pending",
        icon: "/pendingIcon.png",
        color: "#FF5C1F",
        value: "Pending"
    },
    {
        label: "Canceled",
        icon: "/unCheckIcon.png",
        color: "#E20E0E",
        value: "Cancelled"
    },
];

const scrollBarStyle = {
    "&::-webkit-scrollbar": {
        width: "14px",
        height: "14px",
    },
    "&::-webkit-scrollbar-track": {
        borderRadius: "60px",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#E4E4E5",
        borderRadius: "10px",
        border: "4px solid rgba(0, 0, 0, 0)",
        backgroundClip: "padding-box",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#d5d5d5",
    },
};

const OrderStatusSection = ({ handleDrawerToggle, selectedEmail, handleSideBarToggle }) => {
    const [searchText, setSearchText] = useState("");
    const [status, setStatus] = useState("Pending");
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    const [orders, setOrders] = useState([]);
    const [page, setPage] = useState(1);
    const [rowsPerPage,] = useState(15);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItemCount, setSelectedItemCount] = useState(0);
    const [maxCount, setMaxCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const handleSearchChange = async (e) => {
        const query = e.target.value.toLowerCase();
        setSearchText(query);
    };

    const handleStatusChange = (e) => {
        let updatedStatusValue = e.target.value;
        setStatus(updatedStatusValue);
    };

    const fetchOrders = useCallback(async () => {
        let baseUrl = `${API_SERVICE_BACKEND3}/dfy-setup/getOrders?email=${selectedEmail.user}`;
        if (status !== "all" && status !== "") {
            baseUrl = baseUrl + `&status=${status}`;
        }
        if (searchText !== "") {
            baseUrl = baseUrl + `&query=${searchText}`;
        }
        if (page) {
            baseUrl = baseUrl + `&page=${page}`;
        }
        if (rowsPerPage) {
            baseUrl = baseUrl + `&limit=${rowsPerPage}`;
        }
        const response = await axios.get(baseUrl);
        setTotalCount(response.data.total);
        setOrders(response.data.data ?? []);
        setIsLoading(false);
    }, [status, debouncedSearchText, selectedEmail]);

    const handleSelect = (contact, orderId) => {
        const selectedIndex = selectedItems.findIndex((item) => item._id === orderId);
        let newSelected = [];

        if (selectedIndex === -1) {
            setSelectedItemCount(selectedItemCount + 1);
            newSelected = [...selectedItems, { _id: orderId, contact: [contact._id] }];
            setSelectedItems(newSelected);
        } else if (selectedIndex > -1) {
            if (selectedItems[selectedIndex]?.contact?.includes(contact._id)) {
                const updatedContact = selectedItems[selectedIndex].contact.filter((item) => item !== contact._id);
                if (updatedContact.length === 0) {
                    newSelected = selectedItems.filter((item) => item._id !== orderId);
                    setSelectedItems(newSelected);
                } else {
                    selectedItems[selectedIndex].contact = updatedContact;
                    setSelectedItems([...selectedItems]);
                }
                setSelectedItemCount(selectedItemCount - 1);

            } else {
                const updatedContact = [...selectedItems[selectedIndex].contact, contact._id];
                selectedItems[selectedIndex] = { ...selectedItems[selectedIndex], contact: updatedContact };
                setSelectedItemCount(selectedItemCount + 1);
                setSelectedItems([...selectedItems]);

            }
        }

    }
    const updateOrderStatus = async (status, contact, orderId) => {
        const response = await axios.put(`${API_SERVICE_BACKEND3}/dfy-setup/updateOrderStatus/${orderId}`, {
            user: selectedEmail,
            email: contact.email,
            contactId: contact._id,
            status: status.toLowerCase()
        });
        if (response.data.status) {
            toast.success("Status Updated Successfully");
            fetchOrders();
        }
    }
    const updateBulkOrderStatus = async (status) => {
        setIsLoading(true);
        const response = await axios.put(`${API_SERVICE_BACKEND3}/dfy-setup/updateOrderStatus/true`, {
            orders: selectedItems,
            status: status.toLowerCase()
        });
        if (response.data.status) {
            setSelectedItems([]);
            setSelectedItemCount(0);
            toast.success("Status Updated Successfully");
            fetchOrders();
            setIsLoading(false);
        }
    }
    const handleUpdateStatus = async (status, contact, orderId) => {
        updateOrderStatus(status, contact, orderId);
    }
    const handleUpdateSelectedOrdersStatus = async (status) => {
        updateBulkOrderStatus(status);
    }


    const checkSelected = (contact, orderId) => {
        const selectedIndex = selectedItems.findIndex((item) => item._id === orderId);
        if (selectedIndex === -1) {
            return false;
        } else if (selectedIndex > -1) {
            if (selectedItems[selectedIndex].contact.includes(contact._id)) {
                return true;
            }
        }
        return false;
    }
    const handleSelectAll = (event) => {
        let count = 0;
        if (event.target.checked) {
            let newSelected = [];
            orders.forEach((order) => {
                let item = {
                    _id: order._id,
                    contact: []
                }
                order.contactDetails.forEach((contact) => {
                    item.contact.push(contact._id);
                    count++;
                })

                newSelected.push(item);
            }
            )
            setSelectedItems(newSelected);
            setSelectedItemCount(count);
            return;
        }
        setSelectedItems([]);
        setSelectedItemCount(count);
    }

    // Debounce the search text change
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchText(searchText);
        }, 2000);


        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);

    // Fetch orders when status or debouncedSearchText changes
    useEffect(() => {
        
        setIsLoading(true); // Set loading before fetching
        fetchOrders(); // Call fetchOrders with the debounced search text
    }, [status, debouncedSearchText, fetchOrders]);

    useEffect(() => {
        let count = 0;
        if (orders.length > 0) {
            orders.forEach((order) => {
                order.contactDetails.forEach(() => {
                    count++;
                })

            });
            setMaxCount(count);
        }
    }, [orders]);
    useEffect(() => {
        // handleDrawerToggle()
        handleSideBarToggle()
    },[])

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                <Tooltip title="Back">
                    <IconButton
                        onClick={() => {
                            setOrders([]);
                            setSelectedItems([]);
                            setSelectedItemCount(0);
                            setMaxCount(0);
                            setPage(1);
                            setSearchText("");
                            setStatus("Pending");
                            handleDrawerToggle()
                        }}
                        sx={{
                            border: '1px solid #E9E9EC',
                            borderRadius: '12px',
                            padding: '4px 8px',
                            marginRight: '10px'
                        }}
                    >
                        <CloseOutlined />
                    </IconButton>
                </Tooltip>

                <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                    Order Status
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
                <Box sx={{ display: "flex", gap: 1 }}>


                    <OutlinedInput
                        onChange={handleSearchChange}
                        size="small"
                        // disabled={loader}
                        placeholder="Search"
                        startAdornment={
                            <InputAdornment position="start">
                                <SvgIcon>
                                    <SearchOutlined />
                                </SvgIcon>
                            </InputAdornment>
                        }
                        value={searchText}
                    />
                    <CustomDropdown
                        label={status === "" ? "All Status" : status}
                        options={statusList}
                        selected={status}
                        handleSelect={(value) =>
                            handleStatusChange({ target: { value } })
                        }
                    />
                    <Button
                        variant="outlined"
                        onClick={() => window.location.reload()}
                    >
                        back
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    {selectedItemCount > 0 ? (
                        <>
                            <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>Selected:{selectedItemCount}</Typography>

                            <CustomDropdown
                                label={"Select Status"}
                                options={statusListWithoutAll}
                                selected={status}
                                handleSelect={(value) =>
                                    handleUpdateSelectedOrdersStatus(value)
                                }
                            />
                        </>) : null}
                </Box>
            </Box>

            <TableContainer
                sx={{ borderRadius: "0px", ...scrollBarStyle }}
            >
                <Table
                    sx={{
                        height: "fit-content",
                        minWidth: "100%",
                        borderCollapse: "separate",
                        borderSpacing: "0px 15px !important ",
                    }}
                    aria-label="simple table"
                    stickyHeader
                >
                    <TableHead sx={{ background: '#E9E9EC', borderRadius: '10px' }}>
                        <TableRow
                            sx={{
                                "& .MuiTableCell-root": {
                                    backgroundColor: "#E9E9EC",
                                    borderBottom: "1px solid #E9E9EC",
                                    color: "#61616F",
                                },
                                "& .MuiTableCell-root::after": {
                                    content: '""',
                                    position: "absolute",
                                    top: 8,
                                    right: 0,
                                    bottom: 18,
                                    borderRight: '2px solid #CECED3',
                                    height: '50%',
                                    backgroundColor: "#CECED3",
                                },
                                "& .MuiTableCell-root:last-child::after": {
                                    content: 'none',
                                },
                            }}
                        >
                            <TableCell align="left">  <Checkbox
                                checked={orders.length > 0
                                    && maxCount > 0 && selectedItemCount === maxCount}
                                onChange={handleSelectAll}
                                disabled={orders.length === 0}
                                name={""} /> Name</TableCell>
                            <TableCell align="left">Provider</TableCell>
                            <TableCell align="left">Emails</TableCell>
                            <TableCell align="left">Purchase Date</TableCell>
                            <TableCell align="left">Forward Domain</TableCell>
                            <TableCell align="left">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    {!isLoading && orders.length > 0 ?
                        (
                            <TableBody>
                                {orders?.map((data, index) => {

                                    return data?.contactDetails?.map((contact) => {
                                        const statusInfo = statusList.find(
                                            (status) =>
                                                status?.value?.toLowerCase() === contact?.status?.toLowerCase() // Match the status with the label
                                        );
                                        return (

                                            <TableRow
                                                key={index}
                                                sx={{
                                                    cursor: "pointer",
                                                    borderRadius: "10px",
                                                    background: 'white',
                                                    marginBottom: '10px',
                                                    "& td": {
                                                        borderTop: "1px solid rgba(224, 224, 229, 1)",
                                                        borderBottom: "1px solid rgba(224, 224, 229, 1)",
                                                        borderRight: "none",
                                                    },
                                                    "& td:first-child": {
                                                        borderTopLeftRadius: "10px",
                                                        borderBottomLeftRadius: "10px",
                                                        borderLeft: "1px solid rgba(224, 224, 229, 1)",
                                                        borderRight: "none"
                                                    },
                                                    "& td:last-child": {
                                                        borderTopRightRadius: "10px",
                                                        borderBottomRightRadius: "10px",
                                                        borderRight: "1px solid rgba(224, 224, 229, 1)",
                                                        borderLeft: "none"
                                                    }
                                                }}
                                            >
                                                <TableCell align="left" sx={{ borderBottom: 'none', width: 'fit-content', flex: 1, cursor: "default" }}>

                                                    <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '14px', cursor: "default" }}>
                                                        <Checkbox
                                                            checked={selectedItems.length > 0 && checkSelected(contact, data._id)}
                                                            onChange={() => {
                                                                handleSelect(contact, data._id)
                                                            }}
                                                            sx={{ cursor: "pointer" }}
                                                        />
                                                        <LanguageOutlined sx={{ color: "rgba(88, 98, 255, 1)", marginRight: '10px' }} />
                                                        {data?.domain}
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            gap: '6px',
                                                            borderRadius: '10px',
                                                            p: '6px 12px',
                                                            border: "1px solid #E0E0E5",
                                                            width: "fit-content",
                                                        }}
                                                    >
                                                        {data.provider === "google" ? (<><img src={Socialicon} alt="" />
                                                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >Google</Typography></>) : null}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {contact.email}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {data.createdAt ? moment(data.createdAt).format('DD-MM-YYYY') : moment(data.startDate).format('DD-MM-YYYY') ?? "-"}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {data?.forwardDomain ?? "-"}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {statusInfo && (
                                                        <CustomDropdown
                                                            label={statusInfo.label}
                                                            statusIcon={statusInfo.icon}
                                                            options={statusList.slice(1)}
                                                            selected={contact.status}
                                                            handleSelect={(status) => { handleUpdateStatus(status, contact, data._id) }}
                                                        />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                                )}
                            </TableBody>
                        ) : isLoading ? (
                            <TableBody sx={{ margin: "auto", width: "100%", border: "none" }}>
                                <TableRow sx={{ border: "none" }} >
                                    <TableCell colSpan={7} align="center"  >
                                        <CircularProgress style={{ margin: "auto" }} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        <Typography
                                            sx={{
                                                fontSize: "1.5rem",
                                                fontWeight: 500,
                                                color: "#61616F",
                                            }}
                                        >
                                            No Orders Found
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                </Table>
            </TableContainer>
            <Stack spacing={3}>

                {!isLoading && (
                    <Pagination
                        count={
                            Math.ceil(totalCount / rowsPerPage)
                        }
                        page={page}
                        onChange={(event, page) => setPage(page)}
                        showFirstButton
                        showLastButton
                        siblingCount={1}
                        boundaryCount={1}
                        variant="outlined"
                        shape="rounded"
                        size="large"
                        sx={{ mt: 2, display: "flex", justifyContent: "center" }}
                        rowsPerPage={rowsPerPage}
                    />
                )}
            </Stack>
        </>
    );
};

export default OrderStatusSection;