import { lazy } from 'react';
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import UserDataManage from '../pages/user/emailAccounts/UserDataManage';
import CampaignSettings from '../pages/user/campaign/CampaignList';
import Campaign from '../pages/user/campaign/CampaignSettings';
import Profile from 'pages/components/Settings/profile';
import InvoiceTable from 'pages/invoice/invoiceTable/InvoiceTable';
import UserInvoices from 'pages/invoice/userInvoice/UserInvoices';
import Dashboard from '../pages/dashboard/Dashboard';
import UserDetails from 'pages/user/index';
import ManageEmailAccount from 'pages/manageEmailAccount/index';
import Monitor from 'pages/monitor/index';
import EmailAnalytics from 'pages/email-analytics/index';
import Blocklist from 'pages/blocklist/index';
import MonitorQueues from 'pages/monitorQueues/index';
import OrderStatus from 'pages/orderStatus/index';
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/Dashboard')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'manage',
      children: [
        {
          path: 'email',
          element: <UserDataManage />
        },
        {
          path: 'campaigns',
          element: <CampaignSettings />
        },
        {
          path: 'campaign',
          element: <Campaign />
        },
        {
          path: 'invoice',
          element: <InvoiceTable />
        },
        {
          path: 'user',
          element: <UserInvoices />
        },
        {
          path: 'dashboard',
          element: <Dashboard />
        },
        {
          path: 'users',
          element: <UserDetails />
        },
        {
          path: `email-accounts`,
          element: <ManageEmailAccount />
        },
        {
          path: 'monitor',
          element: <Monitor />
        },
        {
          path: 'monitor/:id',
          element: <Monitor />
        },
        {
          path: 'email-accounts/:id',
          element: <ManageEmailAccount />
        },
        {
          path: 'emailAnalytics/:id',
          element: <EmailAnalytics />
        },
        {
          path: 'emailAnalytics',
          element: <EmailAnalytics />
        },
        {
          path: 'blocklist',
          element: <Blocklist />
        },
        {
          path: 'monitorQueues',
          element: <MonitorQueues />
        },
        {
          path: 'orderStatus',
          element: <OrderStatus />
        }
      ]
    },
    {
      path: 'settings',
      element: <Profile />
    },

    {
      path: '*',
      element: <DashboardDefault />
    }
  ]
};

export default MainRoutes;
