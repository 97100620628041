import { UserOutlined, DashboardOutlined, MailOutlined, FileDoneOutlined, DesktopOutlined, BarChartOutlined, MonitorOutlined, BlockOutlined, CheckCircleOutlined } from '@ant-design/icons';
import Campaign from '../assets/icons/Campaign';

const icons = {
  UserOutlined,
  DashboardOutlined,
  MailOutlined,
  FileDoneOutlined,
  DesktopOutlined,
  BarChartOutlined,
  BlockOutlined,
  MonitorOutlined,
  CheckCircleOutlined
};

const dashboard = (location) => {
  return {
    id: 'group-dashboard',
    title: 'Navigation',
    type: 'group',
    children: [
      {
        id: 'dashboard2',
        title: 'Dashboard',
        type: 'manage',
        url: '/manage/dashboard',
        icon: icons.DashboardOutlined,
        breadcrumbs: false,
        show: true
      },
      {
        id: 'user',
        title: 'User',
        type: 'item',
        url: '/manage/users',
        icon: icons.UserOutlined,
        breadcrumbs: false,
        show: true
      },
      {
        id: 'invoice',
        title: 'Invoice',
        type: 'manage',
        url: '/manage/invoice',
        icon: icons.FileDoneOutlined,
        breadcrumbs: false,
        show: true
      },
      {
        id: 'emailAccounts',
        title: 'Email Accounts',
        type: 'manage',
        url: '/manage/email-accounts',
        icon: icons.FileDoneOutlined,
        breadcrumbs: false,
        show: true
      },
      {
        id: 'monitor',
        title: 'Monitor Lead Finder',
        type: 'manage',
        url: '/manage/monitor',
        icon: icons.DesktopOutlined,
        breadcrumbs: false,
        show: true
      },
      {
        id: 'emailAnalytics',
        title: 'Email Analytics',
        type: 'manage',
        url: '/manage/emailAnalytics',
        icon: icons.BarChartOutlined,
        breadcrumbs: false,
        show: true
      },
      {
        id: 'blocklist',
        title: 'Blocklist',
        type: 'manage',
        url: '/manage/blocklist',
        icon: icons.BlockOutlined,
        breadcrumbs: false,
        show: true
      },
      {
        id: 'monitorQueues',
        title: 'Monitor Queues',
        type: 'manage',
        url: '/manage/monitorQueues',
        icon: icons.MonitorOutlined,
        breadcrumbs: false,
        show: true
      },
      {
        id: 'orderStatus',
        title: 'Order Status',
        type: 'manage',
        url: '/manage/orderStatus',
        icon: icons.CheckCircleOutlined,
        breadcrumbs: false,
        show: true
      },
      {
        id: 'email',
        title: 'Email',
        type: 'manage',
        url: '/manage/email',
        icon: icons.MailOutlined,
        breadcrumbs: false,
        show: location.pathname === '/manage/email'
      },
      {
        id: 'campaigns',
        title: 'Campaigns',
        type: 'manage',
        url: '/manage/campaigns',
        icon: Campaign,
        breadcrumbs: false,
        show: location.pathname === '/manage/campaigns'
      },
      {
        id: 'campaign',
        title: 'Campaign',
        type: 'manage',
        url: '/manage/campaign',
        icon: Campaign,
        breadcrumbs: false,
        show: location.pathname === '/manage/campaign'
      }
    ]
  };
};

export default dashboard;
