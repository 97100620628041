import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Modal, TextField, Autocomplete, Checkbox } from "@mui/material";
import { useUpdateWorkspaceMutation, useGetAllWorkspacesMutation } from "services/user-service";
import toast from "react-hot-toast";
import CustomCheckbox from 'pages/components/campaign/CustomCheckbox';
import {
  useDisableEnterpriseMutation,
  useEnableEnterpriseMutation,
} from 'services/auth-service';

export function ChangeEnterpriseUser({ id, open, onClose }) {
  const [limit, setLimit] = useState(0);
  const [selectedWorkspace, setSelectedWorkspace] = useState({});
  const [toggleButton, setToggleButton] = useState();
  const [allWorkspaces, setAllWorkspaces] = useState([]);
  const [enterprisePlans, setEnterprisePlans] = useState({
    warmupPlan: false,
    warmupUiPlan: false,
    campaignPlan: false,
    campaignUiPlan: false,
    analyticsUiPlan: false,
    uniboxUiPlan: false,
  });
  const [enableEnterprise] = useEnableEnterpriseMutation();
  const [disableEnterprise] = useDisableEnterpriseMutation();
  const [getAllWorkspaces] = useGetAllWorkspacesMutation();
  const [updateWorkspace] = useUpdateWorkspaceMutation();

  const getWorkspaces = async () => {
    const workspaces = await getAllWorkspaces({
      params: _.pickBy({ id }),
    }).unwrap();
    setAllWorkspaces(workspaces);
  }

  useEffect(() => {
    if (id) {
      getWorkspaces()
    }
  }, [id]);

  const onChangeValues = (e) => {
    setLimit(e.target.value)
  };

  const handleCancel = () => {
    setLimit(0);
    setSelectedWorkspace({});
    onClose();
    setEnterprisePlans({
      warmupPlan: false,
      warmupUiPlan: false,
      campaignPlan: false,
      campaignUiPlan: false,
      analyticsUiPlan: false,
      uniboxUiPlan: false,
    })
  };

  const handleChange = async () => {
    try {
      const { data } = await updateWorkspace({
        id: selectedWorkspace._id,
        limit,
        enterprisePlans
      });
      getUpdatedWorkSpaces(data?.workspace);
      handleCancel();
      toast.success(data?.message);
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleEnterpriseToggle = async () => {
    try {
      if (toggleButton) {
        //true
        const workspace = await disableEnterprise({
          workspaceId: selectedWorkspace._id,
        });
        setPlanValues(workspace);
        await getUpdatedWorkSpaces(workspace?.data);
        setToggleButton(workspace?.data?.enterprise);
        toast.success('Enterprise is disabled');
      } else {
        //false
        const workspace = await enableEnterprise({
          workspaceId: selectedWorkspace._id,
        });
        await getUpdatedWorkSpaces(workspace?.data);
        setToggleButton(workspace?.data?.enterprise)
        toast.success('Enterprise is enabled');
      }
    } catch (error) {
      console.log(`error`, error)
    }
  };

  const getUpdatedWorkSpaces = async (newWorkspace) => {
    try {
      if (newWorkspace) {
        const updatedWorkspaces = allWorkspaces.map(workspace => {
          if (workspace?._id === newWorkspace?._id) {
            return newWorkspace;
          } else {
            return workspace;
          }
        });
        setAllWorkspaces(updatedWorkspaces);
      }
    } catch (error) {
      console.error("Error fetching workspaces:", error);
    }
  };

  const handleChangeCheckbox = (key, value) => {
    setEnterprisePlans((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const setPlanValues = (currentWorkspace) => {
    setEnterprisePlans({
      warmupPlan: currentWorkspace?.warmup?.warmupPlan,
      warmupUiPlan: currentWorkspace?.warmup?.uiForWarmup,
      campaignPlan: currentWorkspace?.campaign?.campaignPlan,
      campaignUiPlan: currentWorkspace?.campaign?.uiForCampaign,
      analyticsUiPlan: currentWorkspace?.analytics?.uiForAnalytics,
      uniboxUiPlan: currentWorkspace?.analytics?.uiForUnibox,
    });
    setLimit(currentWorkspace?.totalAccounts);
  }

  useEffect(() => {
    if (selectedWorkspace?._id) {
      setPlanValues(selectedWorkspace)
    }
  }, [selectedWorkspace])

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleCancel}
        aria-labelledby="modal-modal-title"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: 'center', gap: '10px', mb: 2 }}>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{ color: "#28287B" }}
            >
              Enterprise User
            </Typography>
            {selectedWorkspace?._id && (
              <CustomCheckbox
                size={'small'}
                checked={toggleButton}
                onClick={() => {
                  handleEnterpriseToggle();
                }}
              />
            )}
          </Box>
          {toggleButton && selectedWorkspace?._id && (
            <>
              {[
                { label: "Warmup Plan", uiLabel: "Warmup UI Plan", stateKey: 'warmupPlan', uiStateKey: 'warmupUiPlan' },
                { label: "Campaign Plan", uiLabel: "Campaign UI Plan", stateKey: 'campaignPlan', uiStateKey: 'campaignUiPlan' },
                { label: "Analytics UI Plan", uiLabel: "Unibox UI Plan", stateKey: 'analyticsUiPlan', uiStateKey: 'uniboxUiPlan' },
              ].map((plan, index) => (
                <Box key={index} sx={{ display: "flex", alignItems: 'center', mb: 2 }}>
                  <Box sx={{ width: "55%", display: "flex", alignItems: "center", gap: '5px' }}>
                    <Checkbox
                      checked={enterprisePlans[plan?.stateKey]}
                      onChange={(e) => handleChangeCheckbox(plan?.stateKey, e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{ color: "#28287B" }}
                    >
                      {plan?.label}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "45%", display: "flex", alignItems: "center", gap: '5px' }}>
                    <Checkbox
                      checked={enterprisePlans[plan?.uiStateKey]}
                      onChange={(e) => handleChangeCheckbox(plan?.uiStateKey, e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{ color: "#28287B" }}
                    >
                      {plan?.uiLabel}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </>
          )}
          <Box sx={{ mb: 2 }}>
            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              options={allWorkspaces}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setSelectedWorkspace(newValue ? newValue : '');
                setToggleButton(newValue ? newValue.enterprise : '')
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select workspace"
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />
            <TextField
              id="change limit"
              label="Change accounts add limit"
              variant="outlined"
              name="limit"
              value={limit}
              onChange={onChangeValues}
              fullWidth
              margin="normal"
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={!selectedWorkspace?._id}
              onClick={handleChange}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

const style = {
  position: "relative",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  outline: "none",
};

export default ChangeEnterpriseUser;
